
  import { defineComponent, ref, computed, watch, getCurrentInstance } from "vue"
  import TitleActionsNavbar from "@/components/shared/TitleActionsNavbar.vue";
  import { Visor } from "@/models/visors";
  import { Agency } from "@/models/agencies";
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { resourceUrl } from "@/utils/dataExtractors"
  import { CollectionResourceDoc, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { BvTableCtxObject } from "bootstrap-vue";
  import Tickets from "@/components/shared/Tickets.vue"
  import { watchForTicketIds } from "@/view_scripts/tickets"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import TicketEdit from "@/components/shared/TicketEdit.vue"

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      TitleActionsNavbar,
      Tickets,
      TicketEdit
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const visor = computed<ResourceObject<string, Visor>>(() => root.$store.state.visors.element)
      const agency = ref<ResourceObject<string, Agency>>()
      const newTicketModal = ref()
      const suggestionTable = ref()
      const ticketIds = ref<string[]>([])
      root.$store.dispatch('visors/get', { id: props.id, queryParams: { include: 'tickets' } })

      const totalSuggestionItems = computed<number>(() => visor.value.attributes?.suggestions?.length ?? 0)

      const getSuggestionItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          // 'sort': '-createdAt',
          include: 'care_stays,tickets,agency'
        }
        const resp = await root.$store.dispatch('visors/get', { id: props.id, queryParams: queryParams })
        // agency.value = resp.included.find((i)=>i.type=='agencies')
        // console.log('agency', agency.value)
        ticketIds.value = extractRelationshipIds({data: [resp]}, 'tickets')
        // console.log('tids',ticketIds.value)
        return visor.value.attributes?.care_stays ?? []
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          label: "Start",
          title: "Start",
          key: 'id',
          sortable: true
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          label: "segmentDiscountPfs",
          title: "segmentDiscountPfs",
          key: 'attributes',
          sortable: true
        }

      ])

      const titleActions = computed(() => [newTicketAction(newTicketModal)])


      const ticketCreated = () => {
        newTicketModal.value.hide()
        suggestionTable.value.refreshItems()
      }

      return {
        visor,
        getSuggestionItems,
        totalSuggestionItems,
        columns,
        ticketIds,
        resourceUrl,
        titleActions,
        newTicketModal,
        ticketCreated,
        suggestionTable
      }
    }
  })
